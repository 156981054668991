import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import imagePath1 from "../img/m7.png";
import imagePath1C from "../img/Compressed/m7.jpg";
import { motion } from "framer-motion";
import Header from "./Header";

function Web() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // 在组件加载时滚动到页面顶部
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto", // 高度自动适应宽度
  };

  return (
    <div>
      <Header />
      <div
        style={{
          position: "relative",
          top: 60,
          backgroundColor: "#1F1F1F",
          width: "100vw",
          height: "auto", // 高度自动适应图片的高度
          display: "flex",
          alignItems: "flex-start", // 图片顶对齐
          justifyContent: "center",
          overflow: "hidden", // 隐藏溢出部分
        }}
      >
        <img src={imagePath1C} alt="AIGC" style={imageStyle} />
      </div>
    </div>
  );
}

export default Web;
