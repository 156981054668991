import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import imagePath1C from "../img/Cover/packagingIndustrialCover.jpg";
import personalCareProductsCover from "../img/Cover/personalcareproductsCover.jpg";
import likkimIndustrialDesignCover from "../img/Cover/likkimIndustrialDesignCover.jpg"; // Ensure this path is correct
import if25 from "../img/IF2025.png";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
function Web() {
  const navigate = useNavigate(); // 创建 navigate 函数实例
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigateToPersonalCareProducts = () => {
    navigate("/personal-care-products"); // 更新此处以匹配你的路由
  };
  const navigateToLikkimIndustrialDesign = () => {
    navigate("/likkim-industrial-design"); // Ensure this path matches your router setup
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // 在组件加载时滚动到页面顶部
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto", // 高度自动适应宽度
  };

  return (
    <div>
      <Header />
      <div
        style={{
          position: "relative",
          top: 60,
          backgroundColor: "#1F1F1F",
          width: "100vw",
          height: "auto", // 高度自动适应图片的高度
          display: "flex",
          overflow: "hidden", // 隐藏溢出部分
          flexDirection: "column", // 垂直排列
          alignItems: "center", // 居中对齐
          justifyContent: "center", // 水平居中
        }}
      >
        <img src={imagePath1C} alt="PackageIndustrial" style={imageStyle} />

        <div
          style={{
            maxWidth: `${maxWidth}px`,
            width: `${calculatedWidth}px`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="pt-[20px] bg-[#1F1F1F] max-w-[${maxWidth}px] w-[${calculatedWidth}px] grid grid-cols-3 sm:grid-cols-1 gap-[2vw] lg:gap-[20px] relative overflow-hidden text-[min(1.2vw,10px)] items-center justify-center"
            style={{
              borderRadius: "10px",
            }}
          >
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${personalCareProductsCover})` }}
              onClick={navigateToPersonalCareProducts}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                个护产品工业设计
              </motion.h1>
            </motion.div>
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${likkimIndustrialDesignCover})` }}
              onClick={navigateToLikkimIndustrialDesign}
            >
              <motion.img
                src={if25}
                alt="Red Dot Award 2022"
                className="h-[40px] sm:h-[36px] w-auto z-[10] absolute top-[10px] right-[10px] h-[40px]"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                Likkim工业设计
              </motion.h1>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Web;
