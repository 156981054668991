import React, { useState, useEffect } from "react";
import likkimIndustrialDesignImage from "../img/likkimIndustrialDesign.png"; // Ensure the path is correct
import Header from "./Header";
import if25 from "../img/IF2025.png";

function LikkimIndustrialDesign() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Handle window resizing
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048; // Max width for the image container
  const widthPercentage = 94; // Width as a percentage of the viewport width
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  // Style for the image to make it responsive
  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto", // Maintain the aspect ratio of the image
  };

  // Style for the container to center the content
  const containerStyle = {
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "60px", // Space for the header
    boxSizing: "border-box",
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <div
          style={{
            position: "relative", // 父容器相对定位
            display: "flex", // 使用 Flexbox 布局
            justifyContent: "center", // 水平居中
            width: "100%", // 容器宽度为 100%
            maxWidth: `${calculatedWidth}px`, // 最大宽度为计算的宽度
            margin: "0 auto", // 确保容器在页面中水平居中
          }}
        >
          <img
            src={likkimIndustrialDesignImage}
            alt="LIKKIM Industrial Design"
            style={imageStyle}
          />
          <img
            src={if25}
            alt="Red Dot Award 2022"
            className="h-[80px] sm:h-[40px] w-auto z-[10] absolute top-[30px] right-[30px]   sm:top-[10px] sm:right-[10px]"
            style={{
              width: "auto", // 宽度自适应
              maxWidth: "100%", // 限制最大宽度为父容器宽度
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default LikkimIndustrialDesign;
