import React, { useState, useEffect } from "react";
import typhurImage from "../img/typhur.png"; // 图片文件路径，请根据实际情况调整
import typhurVideo from "../img/Typhur.mp4"; // 视频文件路径
import Header from "./Header";

function Typhur() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  // 设置视频和图片的统一样式
  const mediaStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  const containerStyle = {
    width: "100vw",
    display: "flex",
    flexDirection: "column", // 垂直排列视频和图片
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "60px", // 为 Header 留出空间
    boxSizing: "border-box",
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        {/* 视频上方自动播放且静音 */}
        <video src={typhurVideo} controls autoPlay muted style={mediaStyle} />
        {/* 图片放在视频下方 */}
        <img src={typhurImage} alt="Typhur 电商视觉" style={mediaStyle} />
      </div>
    </div>
  );
}

export default Typhur;
