import React, { useState, useEffect } from "react";
import imagePath0 from "../img/m0.png"; // 导入图片路径
import NewHeader from "./NewHeader"; // 使用正确的路径和文件名
import vivomotionCover from "../img/Cover/vivomotionCover.jpg";
import tencentTigerIpCover from "../img/Cover/tencenttigeripCover.jpg";
import brandCover from "../img/Cover/brandCover.jpg";
import huaweiCover from "../img/Cover/huaweiCover.jpg";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import dot22 from "../img/Reddot2022w.png";
function Motion() {
  const navigate = useNavigate();

  // 点击按钮后，跳转到 /brand 页面
  const navigateToBrand = () => navigate("/brand");

  const navigateToTencent = () => navigate("/tencent");
  const navigateToVivo = () => navigate("/vivo-motion");
  const navigateToHuawei = () => navigate("/huawei");

  useEffect(() => {
    // 创建 script 元素
    const script = document.createElement("script");
    script.src =
      "https://unpkg.com/@splinetool/viewer@0.9.493/build/spline-viewer.js";
    script.type = "module"; // 设置 script 类型为 module
    script.async = true; // 异步加载 script

    // 添加 script 到页面
    document.body.appendChild(script);

    // 在组件卸载时清理 script
    return () => {
      document.body.removeChild(script);
    };
  }, []); // 空依赖数组意味着这个 effect 只会在 mount 和 unmount 时运行

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // 在组件加载时滚动到页面顶部
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto", // 高度自动适应宽度
  };

  return (
    <div>
      <NewHeader />
      <div>
        <div
          style={{
            position: "relative",
            top: 60,
            backgroundColor: "#1F1F1F",
            width: "100vw",
            height: "auto", // 高度自动适应图片和视频的高度
            display: "flex",
            flexDirection: "column", // 垂直排列
            alignItems: "center", // 水平居中对齐
            justifyContent: "flex-start", // 顶对齐
            overflow: "hidden", // 隐藏溢出部分
          }}
        >
          <img src={imagePath0} alt="Motion" style={imageStyle} />

          <div
            style={{
              maxWidth: `${maxWidth}px`,
              width: `${calculatedWidth}px`,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              className="pt-[20px] bg-[#1F1F1F] max-w-[${maxWidth}px] w-[${calculatedWidth}px] grid grid-cols-3 sm:grid-cols-1 gap-[2vw] lg:gap-[20px] relative overflow-hidden text-[min(1.2vw,10px)] items-center justify-center"
              style={{
                borderRadius: "10px",
              }}
            >
              {/* Tencent 按钮 */}
              <motion.div
                whileHover={{ scale: 0.9 }}
                whileTap={{ scale: 1.1 }}
                className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
                style={{ backgroundImage: `url(${tencentTigerIpCover})` }}
                onClick={navigateToTencent}
              >
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
                <motion.h1
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
                >
                  Tencent
                </motion.h1>
              </motion.div>

              {/* Vivo 按钮 */}
              <motion.div
                whileHover={{ scale: 0.9 }}
                whileTap={{ scale: 1.1 }}
                className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
                style={{ backgroundImage: `url(${vivomotionCover})` }}
                onClick={navigateToVivo}
              >
                <motion.img
                  src={dot22}
                  alt="Red Dot Award 2022"
                  className="h-[60px] sm:h-[56px] w-auto z-[10] absolute top-[10px] right-[10px] h-[40px]"
                />

                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
                <motion.h1
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
                >
                  Vivo
                </motion.h1>
              </motion.div>

              {/* Huawei 按钮 */}
              <motion.div
                whileHover={{ scale: 0.9 }}
                whileTap={{ scale: 1.1 }}
                className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
                style={{ backgroundImage: `url(${huaweiCover})` }}
                onClick={navigateToHuawei}
              >
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
                <motion.h1
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
                >
                  Huawei
                </motion.h1>
              </motion.div>
              <motion.div
                whileHover={{ scale: 0.9 }}
                whileTap={{ scale: 1.1 }}
                className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
                style={{ backgroundImage: `url(${brandCover})` }}
                onClick={navigateToBrand}
              >
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
                <motion.h1
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
                >
                  开场动画
                </motion.h1>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Motion;
