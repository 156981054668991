import React, { useState, useEffect } from "react";
import v3z from "../img/Compressed/v3z.jpg"; // 确保路径正确
import voibonshadow from "../img/voibonshadow.mp4"; // 新增视频文件
import Header from "./Header";

function EcommerceVisual() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  // 使用统一的样式为视频和图片设置响应式宽度
  const mediaStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  // 将容器设置为垂直排列
  const containerStyle = {
    width: "100vw",
    display: "flex",
    flexDirection: "column", // 垂直排列
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "60px", // 为 Header 留出空间
    boxSizing: "border-box",
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        {/* 顶部视频自动播放且静音 */}
        <video src={voibonshadow} controls autoPlay muted style={mediaStyle} />
        {/* 视频下方显示图片 */}
        <img src={v3z} alt="欧美电商视觉" style={mediaStyle} />
      </div>
    </div>
  );
}

export default EcommerceVisual;
