import React, { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";
import "./App.css"; // 导入App.css文件
import { useNavigate } from "react-router-dom";
import climber from "./img/climber.png"; // 导入图片路径
import imagePath1 from "./img/5.png"; // 导入图片路径
import imagePath2 from "./img/2.png"; // 导入图片路径
import imagePath3 from "./img/10.png"; // 导入图片路径
import imagePath4 from "./img/14.png"; // 导入图片路径
import imagePath5 from "./img/16.png"; // 导入图片路径1
import imagePathm1 from "./img/m1.png"; // 导入图片路径
import imagePathm2 from "./img/m2.png"; // 导入图片路径
import imagePathm3 from "./img/m3.png"; // 导入图片路径
import imagePathm4 from "./img/m4.png"; // 导入图片路径
import imagePathm5 from "./img/16.png"; // 导入图片路径1
import imagePath6 from "./img/06.png"; // 导入图片路径
import imagePath7 from "./img/07.png"; // 导入图片路径1
import mp from "./img/mp.jpeg"; // 导入图片路径
import "./font/GT-Sectra-Display-Trial-Super.otf";
import "./index.css";
import Spline from "@splinetool/react-spline";
import if25 from "./img/IF2025.png";
import dot22 from "./img/Reddot2022.png";

const containerStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

const commonStyles = {
  fontSize: "50px",
  fontFamily: "Helvetica Neue, sans-serif",
  fontWeight: "bold",
};

const contentStyles = {
  fontSize: "18px",
  fontFamily: "Roboto, sans-serif",
  textAlign: "left",
};

const bannerStyle = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
};

const subtitleStyle = {
  color: "#F32E60",
};

const divStyle = {
  justifyContent: "center",
  alignItems: "center",
  // height: "500px",
  width: "100%",
  backgroundColor: "#F0F3F8",
  display: "flex",
  flexDirection: "column",
};

const timelineContentStyle = {
  fontSize: "14px",
  fontFamily: "Roboto, sans-serif",
  textAlign: "left",
  // width: "1048px",
  margin: "0 auto",

  // paddingLeft: "50px",
};

const workStyle = {
  width: "100%",
  backgroundColor: "#F0F3F8",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  // height: "1800px",
  justifyContent: "center", // 新增的属性，水平居中
  height: "auto",
};

const imageContainerStyle = {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
  maxWidth: "1048px",
};

const imageRowStyle = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
  gap: "2%",
  // flexDirection: 'column'
};

const imageStyle = {
  width: "200px",
  height: "400px",
  backgroundColor: "lightgray",
  margin: "5px",
  marginBottom: "10px", // 设置底部边距为10px
};

const imageSubtitleStyle = {
  ...contentStyles,

  textAlign: "center",
  marginBottom: "20px", // 设置底部边距为20px
};

const footerStyle = {
  minHeight: "500px",
  width: "100%",
  backgroundColor: "black",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const footerWrapperStyle = {
  paddingTop: "150px",
  width: "100%",
  backgroundColor: "rgb(240, 243, 248)",
};

const footerTitleStyle = {
  ...commonStyles,
  color: "white",
  //  marginBottom: "50px", // 设置底部边距为50px
  textAlign: "center",
};

const containerWrapperStyle = {
  //  width: "1048px",

  margin: "0 auto", // 这里设置左右居中对齐
};

const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.8 },
};

const fadeInUp2 = {
  initial: { opacity: 0, y: 30 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 1, delay: 0.3 },
};

const fadeInUp3 = {
  initial: { opacity: 0, y: 30 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 1, delay: 0.6 },
};

// Banner 组件
function Banner() {
  const handleTitleClick = () => {
    // 滚动页面 1000px
    window.scrollBy({
      top: 700, // 垂直滚动 1000px
      left: 0, // 水平滚动保持不变
      behavior: "smooth", // 平滑滚动
    });
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048; // 最大宽度
  const widthPercentage = 94; // 宽度百分比
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  ); // 计算宽度
  const canvasStyle = {
    position: "absolute",
    backgroundColor: "#EAEDF0",
    top: 0,
    left: 0,
    width: "100%",
    height: "700px",
    zIndex: -1, // 将canvas元素的z-index设置为-1，使其在所有其他内容之后
  };

  return (
    <div style={bannerStyle} id="Banner">
      <Spline
        className="SplineBanner"
        style={{ ...canvasStyle, position: "fixed", index: -10000 }}
        scene="https://prod.spline.design/QOyJftOXCpHP3CN8/scene.splinecode"
      />
      <div
        id="BannerTitle"
        style={{
          color: "black",
          fontFamily: "Helvetica Neue, sans-serif",
          fontWeight: "bold",
          maxWidth: `${maxWidth}px`, // 最大宽度
          width: `${calculatedWidth}px`, // 动态计算的宽度
        }}
      >
        <motion.div
          onClick={handleTitleClick}
          initial={fadeInUp.initial}
          animate={fadeInUp.animate}
          transition={fadeInUp.transition}
          style={{
            marginBottom: "10px", // 设置底部边距为20px
          }}
        >
          <span></span>

          <motion.span
            onClick={handleTitleClick}
            whileHover={{
              // hover 时的样式
              WebkitTextStrokeWidth: "1px",
              WebkitTextStrokeColor: "#F32E60",
              color: "rgba(243, 46, 96, 0.2)",
            }}
            style={{
              ...subtitleStyle,
              fontWeight: 700,
              //    WebkitTextStrokeWidth: '1px',
              WebkitTextStrokeColor: "#F32E60",
              fontFamily: "GTSectraDisplay",
              fontStyle: "italic",
            }}
          >
            Jaime Lee
          </motion.span>
        </motion.div>
        <motion.div
          onClick={handleTitleClick}
          initial={fadeInUp2.initial}
          animate={fadeInUp2.animate}
          transition={fadeInUp2.transition}
          style={{
            fontFamily: "GTSectraDisplay",
            fontWeight: "bold",
            marginBottom: "50px",
            fontStyle: "italic",
          }}
        >
          <span>This is my resume</span>
        </motion.div>
        <motion.a
          href="/李佳奇简历（包含作品集）.pdf"
          download
          whileHover={{
            // hover 时的样式
            WebkitTextStrokeWidth: "1px",
            WebkitTextStrokeColor: "#000",
            color: "rgba(0, 0, 0, 0.2)",
          }}
          style={{
            fontWeight: 700,
            //    WebkitTextStrokeWidth: '1px',
            WebkitTextStrokeColor: "#000",
            fontFamily: "GTSectraDisplay",
            fontStyle: "italic",
          }}
          //          className="ml-2 inline-block px-6 py-3 bg-gray-500 text-white font-bold text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-600 hover:shadow-lg focus:bg-gray-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-700 active:shadow-lg transition duration-150 ease-in-out"
          className="text-[30px]"
        >
          Download Resume PDF
        </motion.a>
        <div className="flex items-center space-x-10 sm:justify-center sm:mt-[40px] mt-[60px]">
          {/* Adds horizontal space between children */}
          <motion.img
            src={if25}
            alt="IF Award 2025"
            className="h-[80px] sm:h-[60px] w-auto"
          />
          {/* Height is 60px and width is auto */}
          <motion.img
            src={dot22}
            alt="Red Dot Award 2022"
            className="h-[80px] sm:h-[60px] w-auto"
          />
          {/* Same styling as the first image */}
        </div>
      </div>
    </div>
  );
}

function DivWithText() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // 可以根据需要调整此值，决定元素进入视图多少时触发
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048; // 最大宽度
  const widthPercentage = 94; // 宽度百分比
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  ); // 计算宽度

  return (
    <div style={divStyle} className="SectionDivStyle">
      <div
        style={{
          ...containerWrapperStyle,
          maxWidth: `${maxWidth}px`, // 最大宽度
          width: `${calculatedWidth}px`, // 动态计算的宽度
        }}
      >
        <motion.div
          ref={ref}
          initial={fadeInUp.initial}
          animate={inView ? fadeInUp.animate : fadeInUp.initial}
          transition={fadeInUp.transition}
          style={{
            fontFamily: "GTSectraDisplay",
            fontWeight: "bold",
            margin: "80px 0px",
            fontStyle: "italic",
            //  marginBottom: "50px",
          }}
          className="SectionTitle"
        >
          <span>My Journey</span>
        </motion.div>
        {/*        <motion.div
             ref={ref}
          initial={fadeInUp2.initial}
          animate={inView ? fadeInUp2.animate : fadeInUp2.initial}
          transition={fadeInUp2.transition}
        
         style={divContentStyle}>
      <span>
            我是Jaime
            Lee，一位拥有丰富多彩设计经验的设计专业人士。我的职业旅程涵盖了从UI/UX设计到动效交互设计，再到设计管理，积累了多年的宝贵经验。在华为技术，我不仅成功设计并推出了多个项目，还领导设计团队，提升了团队协作效率和项目交付质量。同时，我还有维沃移动通讯有限公司的工作经历，曾担任高级动效交互设计师，主要负责UIUX与动效与编程结合方向的工作。
            <br />
            <br />
            我最近的工作经历在深圳市昂戈科技有限公司，担任设计总监一职。我在该职位上负责品牌设计一体化，流程优化，以及领导激发设计团队的工作。在这期间，我成功领导了Voibon和Exertier品牌的多个设计项目，显著提升了用户体验和品牌价值。
            <br />
            <br />
            我以敏锐的洞察力、创新思维和团队领导技能自豪，希望能为贵公司的设计和品牌战略做出积极的贡献。我期待着与您深入探讨如何将我的多样化设计经验和领导才能与贵公司的使命和愿景相结合，共同创造卓越的设计解决方案。
            <br />
            <br />
            诚挚期待与您进一步深入交流，探讨如何共同开创出色的设计项目。
          </span>
        </motion.div> */}
      </div>
    </div>
  );
}

function Timeline() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // 可以根据需要调整此值，决定元素进入视图多少时触发
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048; // 最大宽度
  const widthPercentage = 80; // 宽度百分比
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  ); // 计算宽度

  const { scrollY } = useScroll();

  const color2011 = useTransform(
    scrollY,
    [0, 960, 970, 980, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const color2012 = useTransform(
    scrollY,
    [0, 979, 989, 999, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const color2014 = useTransform(
    scrollY,
    [0, 998, 1008, 1018, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const color2015 = useTransform(
    scrollY,
    [0, 1017, 1027, 1037, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const color2016 = useTransform(
    scrollY,
    [0, 1036, 1046, 1056, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const color2019 = useTransform(
    scrollY,
    [0, 1055, 1065, 1075, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const color2021 = useTransform(
    scrollY,
    [0, 1074, 1084, 1094, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const color2022 = useTransform(
    scrollY,
    [0, 1093, 1103, 1113, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );

  const mcolor2011 = useTransform(
    scrollY,
    [0, 1560, 1570, 1580, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const mcolor2012 = useTransform(
    scrollY,
    [0, 1579, 1589, 1599, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const mcolor2014 = useTransform(
    scrollY,
    [0, 1598, 1608, 1618, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const mcolor2015 = useTransform(
    scrollY,
    [0, 1617, 1627, 1637, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const mcolor2016 = useTransform(
    scrollY,
    [0, 1636, 1646, 1656, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const mcolor2019 = useTransform(
    scrollY,
    [0, 1655, 1665, 1675, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const mcolor2021 = useTransform(
    scrollY,
    [0, 1674, 1684, 1694, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  const mcolor2022 = useTransform(
    scrollY,
    [0, 1693, 1703, 1713, 2000], // 调整此范围以控制加粗开始和结束的滚动位置
    ["#6B6F75", "#6B6F75", "#F32E60", "#6B6F75", "#6B6F75"]
  );
  return (
    <div
      style={{
        // borderRadius: '60px',
        //minHeight: "500px",
        width: "100%",
        backgroundColor: "rgb(240, 243, 248)",
        display: "flex",
        alignItems: "center", // 居中对齐
        justifyContent: "center", // 居中对齐
      }}
      className="SectionDivStyle"
    >
      <motion.div
        ref={ref}
        initial={fadeInUp.initial}
        animate={inView ? fadeInUp.animate : fadeInUp.initial}
        transition={fadeInUp.transition}
        className="HeroWindow"
        style={{
          borderRadius: "40px",
          //minHeight: "500px",
          //  height:"800px",
          width: "93%",
          backgroundColor: "#DCDDE2",
          display: "flex",
          alignItems: "center", // 居中对齐
          justifyContent: "center", // 居中对齐
          backgroundImage: `url(${climber})`, // 设置背景图片路径
          backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
          backgroundPosition: "center center", // 设置背景图片的中心对齐
        }}
      >
        <motion.div
          id="HeroSection"
          ref={ref}
          initial={fadeInUp2.initial}
          animate={inView ? fadeInUp2.animate : fadeInUp2.initial}
          transition={fadeInUp2.transition}
          style={{
            display: "flex",

            // maxWidth: "1048px", // 设置最大宽度为 1048px
            margin: "0 auto", // 居中容器

            maxWidth: `${maxWidth}px`, // 最大宽度
            width: `${calculatedWidth}px`, // 动态计算的宽度
          }}
        >
          <div className="MobileWork" style={timelineContentStyle}>
            <motion.div
              style={{
                marginBottom: "22px",
                color: mcolor2011,
                fontSize: "15px",
                fontWeight: 500,

                borderRadius: "50%",
                filter:
                  " drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8)) drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8)) drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.8))drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.8)) ",
              }}
            >
              2011年9月，考入中央美术学院本科-全国美院排名综合第一
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: mcolor2012,
                fontSize: "15px",
                fontWeight: 500,

                filter:
                  " drop-shadow(0px 0px 3px rgba(255, 255, 255, 0.8)) drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.8)) drop-shadow(0px 0px 12px rgba(255, 255, 255, 0.8))drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.2)) ",
              }}
            >
              2012年6月-2013年1月，巴黎国立高等美术学院交换学习-世界四大美术学院之首
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: mcolor2014,
                fontSize: "15px",
                fontWeight: 500,

                // filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2014年6月-2014年12月，美国哥伦比亚大学商学院交换生
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: mcolor2015,
                fontSize: "15px",
                fontWeight: 500,

                //filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2015年6月，中央美术学院毕业
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: mcolor2016,
                fontSize: "15px",
                fontWeight: 500,

                // filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2017年1月，深圳市新三板服务中心 - UIUX设计师
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: mcolor2019,
                fontSize: "15px",
                fontWeight: 500,

                //filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2019年5月，华为技术有限公司 - 高级UIUX设计师-组长
            </motion.div>

            <motion.div
              style={{
                marginBottom: "22px",
                color: mcolor2021,
                fontSize: "15px",
                fontWeight: 500,

                //filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2021年9月，维沃移动通讯有限公司 - 高级动效交互设计师-组长
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: mcolor2022,
                fontSize: "15px",
                fontWeight: 500,

                //filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2022年8月，深圳市昂戈科技有限公司-设计总监
            </motion.div>
          </div>

          <div className="PCWork" style={timelineContentStyle}>
            <motion.div
              style={{
                marginBottom: "22px",
                color: color2011,
                fontSize: "15px",
                fontWeight: 500,

                // filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2011年9月，考入中央美术学院本科-全国美院排名综合第一
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: color2012,
                fontSize: "15px",
                fontWeight: 500,

                // filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2012年6月-2013年1月，巴黎美术学院交换学习-世界四大美术学院之首
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: color2014,
                fontSize: "15px",
                fontWeight: 500,

                // filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2014年6月-2014年9月，美国哥伦比亚大学商学院交换生
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: color2015,
                fontSize: "15px",
                fontWeight: 500,
              }}
            >
              2015年6月，中央美术学院毕业
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: color2016,
                fontSize: "15px",
                fontWeight: 500,

                // filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2016年1月，深圳市新三板服务中心 - UIUX设计师
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: color2019,
                fontSize: "15px",
                fontWeight: 500,

                // filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2019年5月，华为技术有限公司 - 高级UIUX设计师组长
            </motion.div>

            <motion.div
              style={{
                marginBottom: "22px",
                color: color2021,
                fontSize: "15px",
                fontWeight: 500,

                filter:
                  " drop-shadow(0px 0px 8px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 16px rgba(255, 255, 255, 1))drop-shadow(0px 0px 24px rgba(255, 255, 255, 1)) ",
              }}
            >
              2021年9月，维沃移动通讯有限公司 - 高级动效交互设计师组长
            </motion.div>
            <motion.div
              style={{
                marginBottom: "22px",
                color: color2022,
                fontSize: "15px",
                fontWeight: 500,
                //filter:' drop-shadow(0px 0px 5px rgba(255, 255, 255, 1)) drop-shadow(0px 0px 10px rgba(255, 255, 255, 1))drop-shadow(0px 0px 15px rgba(255, 255, 255, 1)) ',
              }}
            >
              2022年8月，深圳市昂戈科技有限公司 - 设计总监
            </motion.div>
          </div>

          <div
            id="HeroTitle"
            className="SectionTitle"
            style={{
              fontFamily: "Helvetica Neue, sans-serif",
              fontWeight: "bold",
              textAlign: "right",
              width: `${calculatedWidth * 1}px`,
              display: "flex",
              alignItems: "center", // 垂直居中
            }}
          >
            <motion.div
              ref={ref}
              initial={fadeInUp3.initial}
              animate={inView ? fadeInUp3.animate : fadeInUp3.initial}
              transition={fadeInUp3.transition}
              style={{
                fontWeight: 700,
                fontFamily: "GTSectraDisplay",
                fontStyle: "italic",
              }}
              className="FromZeroToHero"
            >
              <span>From </span>
              <motion.span
                whileHover={{
                  WebkitTextStrokeWidth: "1px",
                  WebkitTextStrokeColor: "#F32E60",
                  color: "rgba(243, 46, 96, 0.2)",
                }}
                style={{
                  ...subtitleStyle,
                  fontWeight: 700,
                  //     WebkitTextStrokeWidth: '1px',
                  WebkitTextStrokeColor: "#F32E60",
                }}
              >
                Zero{" "}
              </motion.span>
              <br />
              <span>to </span>
              <motion.span
                whileHover={{
                  WebkitTextStrokeWidth: "1px",
                  WebkitTextStrokeColor: "#F32E60",
                  color: "rgba(243, 46, 96, 0.2)",
                }}
                style={{
                  ...subtitleStyle,
                  fontWeight: 700,
                  //        WebkitTextStrokeWidth: '1px',
                  WebkitTextStrokeColor: "#F32E60",
                }}
              >
                Hero
              </motion.span>
            </motion.div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

function Work() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048; // 最大宽度
  const widthPercentage = 94; // 宽度百分比
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  ); // 计算宽度

  const maxWidth4 = 450; // 最大宽度
  const widthPercentage4 = 42.5; // 宽度百分比
  const calculatedWidth4 = Math.min(
    maxWidth,
    (widthPercentage4 / 100) * windowWidth
  ); // 计算宽度

  const maxWidth6 = 570; // 最大宽度
  const widthPercentage6 = 48.5; // 宽度百分比
  const calculatedWidth6 = Math.min(
    maxWidth,
    (widthPercentage6 / 100) * windowWidth
  ); // 计算宽度

  const navigate = useNavigate();

  const handleDivClick = (route) => {
    navigate(route);
  };
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1, // 可以根据需要调整此值，决定元素进入视图多少时触发
  });
  return (
    <div style={workStyle} className="WorkSectionAll">
      <motion.div
        ref={ref}
        initial={fadeInUp.initial}
        animate={inView ? fadeInUp.animate : fadeInUp.initial}
        transition={fadeInUp.transition}
        className="SectionTitle"
        style={{
          fontFamily: "Helvetica Neue, sans-serif",
          fontWeight: "bold",
          marginTop: "100px",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            maxWidth: `${maxWidth}px`, // 最大宽度
            width: `${calculatedWidth}px`, // 动态计算的宽度
          }}
        >
          <span
            style={{
              fontFamily: "GTSectraDisplay",
              fontStyle: "italic",
              paddingLeft: 5,
            }}
          >
            My Work
          </span>
        </div>
      </motion.div>
      <motion.div
        ref={ref}
        initial={fadeInUp2.initial}
        animate={inView ? fadeInUp2.animate : fadeInUp2.initial}
        transition={fadeInUp2.transition}
        style={imageContainerStyle}
      >
        <div style={{ ...imageRowStyle, flexDirection: "column" }}>
          <div className="PCWork hidden lg:block">
            <motion.div
              onClick={() => handleDivClick("/appuiux")}
              initial={{ scale: 1 }} // 初始缩放比例为1
              whileHover={{ scale: 0.97 }} // 鼠标悬停时缩放到1.2倍
              // transition={{ duration: 0.2 }} // 动画持续时间
              transition={{
                type: "spring",
                stiffness: 200, // 调整弹簧刚度
                damping: 9, // 调整弹簧阻尼
              }}
              style={{
                borderRadius: "40px",
                height: "500px",
                backgroundColor: "lightgray",
                margin: "5px",
                marginBottom: "10px", // 设置底部边距为10px
                maxWidth: `${maxWidth}px`, // 最大宽度
                width: `${calculatedWidth}px`, // 动态计算的宽度
                marginLeft: "auto", // 左右居中
                marginRight: "auto", // 左右居中
                alignSelf: "flex-start",

                backgroundImage: `url(${imagePath1})`, // 设置背景图片路径
                backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                backgroundPosition: "center center", // 设置背景图片的中心对齐
              }}
            >
              {/*  <span>图片1</span> */}
            </motion.div>
            {/*           className="MobileWork" */}
            <div
              style={{
                ...imageSubtitleStyle,
                alignSelf: "flex-start",
                width: `${calculatedWidth}px`,
                marginLeft: "auto", // 左右居中
                marginRight: "auto", // 左右居中
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>01.&nbsp;</span>
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                App UIXU
              </div>
            </div>
          </div>
        </div>

        <div style={{ ...imageRowStyle, flexDirection: "column" }}>
          <div className="MobileWork hidden sm:block md:block">
            <motion.div
              onClick={() => handleDivClick("/appuiux")}
              initial={{ scale: 1 }} // 初始缩放比例为1
              whileHover={{ scale: 0.97 }} // 鼠标悬停时缩放到1.2倍
              // transition={{ duration: 0.2 }} // 动画持续时间
              transition={{
                type: "spring",
                stiffness: 200, // 调整弹簧刚度
                damping: 9, // 调整弹簧阻尼
              }}
              style={{
                borderRadius: "40px",
                height: "500px",
                backgroundColor: "lightgray",
                margin: "5px",
                marginBottom: "10px", // 设置底部边距为10px
                maxWidth: `${maxWidth}px`, // 最大宽度
                width: `${calculatedWidth}px`, // 动态计算的宽度
                marginLeft: "auto", // 左右居中
                marginRight: "auto", // 左右居中
                alignSelf: "flex-start",
                backgroundImage: `url(${imagePathm1})`, // 设置背景图片路径
                backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                backgroundPosition: "center center", // 设置背景图片的中心对齐
              }}
            >
              {/*  <span>图片1</span> */}
            </motion.div>
            {/*           className="MobileWork" */}
            <div
              style={{
                ...imageSubtitleStyle,
                alignSelf: "flex-start",
                width: `${calculatedWidth}px`,
                marginLeft: "auto", // 左右居中
                marginRight: "auto", // 左右居中
                textAlign: "left",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span>01.&nbsp;</span>
              <div
                style={{
                  fontWeight: "bold",
                }}
              >
                App UIXU
              </div>
            </div>
          </div>
        </div>

        <div className="PCWork">
          <div style={imageRowStyle} className="WorkSection">
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/web")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  borderRadius: "40px",
                  ...imageStyle,
                  maxWidth: `${maxWidth4}px`, // 最大宽度
                  width: `${calculatedWidth4}px`, // 动态计算的宽度
                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePath2})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>02.&nbsp;</span>
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Web UIXU
                </div>
              </div>
            </div>

            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
                // justifyContent: "center", // 将子元素在主轴上水平居中
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/ecommerce")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  ...imageStyle,
                  maxWidth: `${maxWidth6}px`, // 最大宽度
                  width: `${calculatedWidth6}px`, // 动态计算的宽度
                  borderRadius: "40px",
                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePath3})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>03.&nbsp;</span>

                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  E-Commerce
                </div>
              </div>
            </div>
          </div>
          <div style={imageRowStyle} className="WorkSection">
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/visual")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  ...imageStyle,
                  maxWidth: `${maxWidth6}px`, // 最大宽度
                  width: `${calculatedWidth6}px`, // 动态计算的宽度
                  borderRadius: "40px",
                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePath4})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <span>04.&nbsp;</span>{" "}
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Visual Creations
                </div>
              </div>
            </div>

            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/motion")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  ...imageStyle,
                  borderRadius: "40px",
                  maxWidth: `${maxWidth4}px`, // 最大宽度
                  width: `${calculatedWidth4}px`, // 动态计算的宽度

                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePath5})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>

              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <span>05.&nbsp;</span>
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Motion Design
                </div>
              </div>
            </div>
          </div>{" "}
          <div style={imageRowStyle} className="WorkSection">
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/packageindustrial")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  borderRadius: "40px",
                  ...imageStyle,
                  maxWidth: `${maxWidth4}px`, // 最大宽度
                  width: `${calculatedWidth4}px`, // 动态计算的宽度
                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePath6})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>06.&nbsp;</span>
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Packaging & Industrial
                </div>
              </div>
            </div>

            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
                // justifyContent: "center", // 将子元素在主轴上水平居中
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/aigc")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  ...imageStyle,
                  maxWidth: `${maxWidth6}px`, // 最大宽度
                  width: `${calculatedWidth6}px`, // 动态计算的宽度
                  borderRadius: "40px",
                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePath7})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>07.&nbsp;</span>

                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  AIGC
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            justifyContent: "center",
          }}
          className="MobileWork"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              gap: "2%",
              flexDirection: "column",
            }}
            className="WorkSection"
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/web")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  ...imageStyle,
                  borderRadius: "40px",
                  width: `94vw`, // 动态计算的宽度
                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePathm2})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>02.&nbsp;</span>
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Web UIXU
                </div>
              </div>
            </div>

            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
                // justifyContent: "center", // 将子元素在主轴上水平居中
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/ecommerce")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  borderRadius: "40px",
                  ...imageStyle,
                  width: `94vw`, // 动态计算的宽度

                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePathm3})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>03.&nbsp;</span>

                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  E-Commerce
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              gap: "2%",
              flexDirection: "column",
            }}
            className="WorkSection"
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/visual")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  borderRadius: "40px",
                  ...imageStyle,
                  width: `94vw`, // 动态计算的宽度

                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePathm4})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <span>04.&nbsp;</span>{" "}
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Visual Creations
                </div>
              </div>
            </div>

            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/motion")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  ...imageStyle,
                  width: `94vw`, // 动态计算的宽度
                  borderRadius: "40px",
                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePathm5})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {" "}
                <span>05.&nbsp;</span>
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Motion Design
                </div>
              </div>
            </div>
          </div>
          <div style={imageRowStyle} className="WorkSection">
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/packageindustrial")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  ...imageStyle,
                  width: `94vw`, // 动态计算的宽度
                  borderRadius: "40px",
                  alignSelf: "flex-start",
                  backgroundImage: `url(${mp})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>06.&nbsp;</span>
                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  Packaging & Industrial
                </div>
              </div>
            </div>

            <div
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "flex-start",
                // justifyContent: "center", // 将子元素在主轴上水平居中
              }}
            >
              <motion.div
                className="WorkPart"
                onClick={() => handleDivClick("/aigc")}
                initial={{ scale: 1 }} // 初始缩放比例为1
                whileHover={{ scale: 0.95 }} // 鼠标悬停时缩放到1.2倍
                // transition={{ duration: 0.2 }} // 动画持续时间
                transition={{
                  type: "spring",
                  stiffness: 200, // 调整弹簧刚度
                  damping: 9, // 调整弹簧阻尼
                }}
                style={{
                  ...imageStyle,
                  width: `94vw`, // 动态计算的宽度
                  borderRadius: "40px",
                  alignSelf: "flex-start",
                  backgroundImage: `url(${imagePath7})`, // 设置背景图片路径
                  backgroundSize: "cover", // 背景图片大小属性，可以根据需求调整
                  backgroundPosition: "center center", // 设置背景图片的中心对齐
                }}
              ></motion.div>
              <div
                style={{
                  ...imageSubtitleStyle,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <span>07.&nbsp;</span>

                <div
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  AIGC
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}
function Footer() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    // 你可以在这里添加更多的初始化代码，如果需要的话
    setIsLoaded(true);
  }, []);

  if (!isLoaded) {
    return <div>Loading Spline Animation...</div>;
  }

  return (
    <div style={footerStyle}>
      <div style={footerTitleStyle}>
        <div
          style={{
            fontWeight: 700,
            fontFamily: "GTSectraDisplay",
            fontStyle: "italic",
            marginTop: "90px",
          }}
        >
          <span>Contact Me</span>

          <Spline
            style={{
              marginTop: "-60px",
            }}
            scene="https://prod.spline.design/8XZMw5SzDzqoHVKF/scene.splinecode"
          />
        </div>
      </div>

      {/*       <div style={buttonStyle}>微信J18038163520</div> */}
    </div>
  );
}

function App() {
  return (
    <div style={containerStyle}>
      <Banner />
      <DivWithText />
      <Timeline />
      <Work />
      <div style={footerWrapperStyle}>
        <Footer />
      </div>
    </div>
  );
}

export default App;
