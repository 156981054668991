import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion"; // 确保引入 motion

function Header() {
  const navigate = useNavigate(); // 使用 useNavigate 钩子

  const headerStyle = {
    backgroundColor: "#1F1F1F",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
    color: "#fff",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
  };

  const backButtonStyle = {
    color: "#fff",
    textDecoration: "none",
    marginRight: "20px",
    width: 45,
    cursor: "pointer", // 添加手形光标
  };

  // 函数处理返回操作
  const handleBack = () => {
    navigate(-1); // 导航到历史的上一项
  };

  return (
    <div style={headerStyle}>
      <div style={backButtonStyle} onClick={handleBack}>
        <motion.div whileHover={{ scale: 1.15 }} whileTap={{ scale: 0.85 }}>
          返回
        </motion.div>
      </div>
    </div>
  );
}

export default Header;
