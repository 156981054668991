import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import imagePath1 from "../img/uo.png"; // 导入图片路径
import { motion } from "framer-motion";
import u1z from "../img/Compressed/u1z.jpg";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import huaweiportalCover from "../img/Cover/huaweiportalCover.jpg";
import tencentqqweatherCover from "../img/Cover/tencentqqweatherCover.jpg";
import xiaoduhmiCover from "../img/Cover/xiaoduhmiCover.jpg";

function AppUIUX() {
  const navigate = useNavigate(); // This replaces useHistory

  // Navigation functions
  const navigateToHuawei = () => {
    navigate("/huawei-portal");
  };

  const navigateToTencentQQ = () => {
    navigate("/tencent-qq-weather");
  };

  const navigateToXiaoDu = () => {
    navigate("/xiaodu-hmi");
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // 在组件加载时滚动到页面顶部
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto", // 高度自动适应宽度
  };

  return (
    <div>
      <Header />
      <div
        style={{
          position: "relative",
          top: 60,
          backgroundColor: "#1F1F1F",
          width: "100vw",
          height: "auto", // 高度自动适应图片的高度
          display: "flex",
          overflow: "hidden", // 隐藏溢出部分
          flexDirection: "column", // 垂直排列
          alignItems: "center", // 居中对齐
          justifyContent: "center", // 水平居中
        }}
      >
        <img src={u1z} alt="李佳奇UIUX作品集1" style={imageStyle} />
        <div
          style={{
            maxWidth: `${maxWidth}px`,
            width: `${calculatedWidth}px`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="pt-[20px] bg-[#1F1F1F] max-w-[${maxWidth}px] w-[${calculatedWidth}px] grid grid-cols-3 sm:grid-cols-1 gap-[2vw] lg:gap-[20px] relative overflow-hidden text-[min(1.2vw,10px)] items-center justify-center"
            style={{
              borderRadius: "10px",
            }}
          >
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{
                backgroundImage: `url(${huaweiportalCover})`,
              }}
              onClick={navigateToHuawei}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center whitespace-nowrap font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                华为门户App亿家
              </motion.h1>
            </motion.div>

            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{
                backgroundImage: `url(${tencentqqweatherCover})`,
              }}
              onClick={navigateToTencentQQ}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60"></div>
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center whitespace-nowrap font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                腾讯QQ天气
              </motion.h1>
            </motion.div>

            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{
                backgroundImage: `url(${xiaoduhmiCover})`,
              }}
              onClick={navigateToXiaoDu}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60"></div>
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center whitespace-nowrap font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                小度HMI
              </motion.h1>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppUIUX;
