import React, { useState, useEffect } from "react";
import Header from "./Header"; // 或者 NewHeader，根据你的项目需求
// 导入视频文件，确保路径正确
import v1 from "../img/New_Horizon_Space.mp4";
import v2 from "../img/New_Horizon_Fantasy_Logo.mp4";
import v3 from "../img/New_Horizon_Mountains Logo.mp4";
import v4 from "../img/New_Horizon_Solid Logo Reveal_1080p .mp4";
import v5 from "../img/NewHorizon_Mountain Peaks Opener.mp4";
import v6 from "../img/New_Horizon_Live Nature English.mp4";
import v7 from "../img/New_Horizon_Cinematic Flames.mp4";

function Brand() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // 监听窗口大小变化
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 页面加载时滚动到顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const videoStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  return (
    <div>
      <Header />
      <div
        style={{
          paddingTop: "60px", // 为 Header 留出空间
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          backgroundColor: "#1F1F1F",
          minHeight: "100vh",
          width: "100vw",
        }}
      >
        <video controls src={v1} alt="brand" style={videoStyle} />
        <video controls src={v2} alt="brand" style={videoStyle} />
        <video controls src={v3} alt="brand" style={videoStyle} />
        <video controls src={v4} alt="brand" style={videoStyle} />
        <video controls src={v5} alt="brand" style={videoStyle} />
        <video controls src={v6} alt="brand" style={videoStyle} />
        <video controls src={v7} alt="huawei" style={videoStyle} />
      </div>
    </div>
  );
}

export default Brand;
