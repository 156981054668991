import React from "react";
import ReactDOM from "react-dom/client";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { useEffect } from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppUIUX from "./components/appuiux";
import Ecommerce from "./components/ecommerce";
import Motion from "./components/motion";
import Visual from "./components/visual";
import Web from "./components/web";
import New from "./components/new";
import AIGC from "./components/AIGC";
import PackageIndustrial from "./components/PackageIndustrial";
import HuaweiPortal from "./components/HuaweiPortal";
import TencentQQWeather from "./components/TencentQQWeather";
import XiaoDuHMI from "./components/XiaoDuHMI";
import VoibonOfficial from "./components/VoibonOfficial";
import HuaweiCloudAPI from "./components/HuaweiCloudAPI";
import HuaweiGlobal from "./components/HuaweiGlobal";
import TencentTiger from "./components/TencentTiger";
import EcommerceVisual from "./components/EcommerceVisual";
import ThreeDCGRendering from "./components/ThreeDCGRendering";
import Vivo from "./components/Vivo";
import OilPainting from "./components/OilPainting";
import HuaweiToB from "./components/HuaweiToB";
import HuaweiToC from "./components/HuaweiToC";
import USAEcommerce from "./components/USAEcommerce";
import EuropeEcommerce from "./components/EuropeEcommerce";
import PersonalCareProducts from "./components/PersonalCareProducts";
import LikkimIndustrialDesign from "./components/LikkimIndustrialDesign";
import Typhur from "./components/Typhur";
import Brand from "./components/Brand";
import Tencent from "./components/Tencent";
import VivoMotion from "./components/VivoMotion";
import Huawei from "./components/Huawei";

const root = ReactDOM.createRoot(document.getElementById("root"));

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    // 这里是触发 Clarity 页面跟踪的地方
    // 例如: window.clarity("track", "PageView");
    //console.log('Page view tracked for:', location.pathname); // 用于测试的日志
  }, [location]);

  return null; // 该组件不渲染任何内容
};

root.render(
  <React.StrictMode>
    <Router>
      <TrackPageView />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/new" element={<New />} />
        <Route path="/appuiux" element={<AppUIUX />} />
        <Route path="/ecommerce" element={<Ecommerce />} />
        <Route path="/motion" element={<Motion />} />
        <Route path="/visual" element={<Visual />} />
        <Route path="/web" element={<Web />} />
        <Route path="/New" element={<New />} />
        <Route path="/aigc" element={<AIGC />} />
        <Route path="/packageindustrial" element={<PackageIndustrial />} />
        <Route path="/huawei-portal" element={<HuaweiPortal />} />
        <Route path="/tencent-qq-weather" element={<TencentQQWeather />} />
        <Route path="/xiaodu-hmi" element={<XiaoDuHMI />} />
        <Route path="/voibon-official" element={<VoibonOfficial />} />
        <Route path="/huawei-cloud-api" element={<HuaweiCloudAPI />} />
        <Route path="/huawei-global" element={<HuaweiGlobal />} />
        <Route path="/tencent-tiger" element={<TencentTiger />} />
        <Route path="/ecommerce-visual" element={<EcommerceVisual />} />
        <Route path="/3d-cg-rendering" element={<ThreeDCGRendering />} />
        <Route path="/vivo" element={<Vivo />} />
        <Route path="/oil-painting" element={<OilPainting />} />
        <Route path="/huawei-tob" element={<HuaweiToB />} />
        <Route path="/huawei-toc" element={<HuaweiToC />} />
        <Route path="/us-ecommerce" element={<USAEcommerce />} />
        <Route path="/europe-ecommerce" element={<EuropeEcommerce />} />
        <Route
          path="/personal-care-products"
          element={<PersonalCareProducts />}
        />
        <Route
          path="/likkim-industrial-design"
          element={<LikkimIndustrialDesign />}
        />
        <Route path="/typhur" element={<Typhur />} />
        <Route path="/brand" element={<Brand />} />
        <Route path="/tencent" element={<Tencent />} />
        <Route path="/vivo-motion" element={<VivoMotion />} />
        <Route path="/huawei" element={<Huawei />} />
      </Routes>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
