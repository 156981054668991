import React, { useState, useEffect } from "react";
import imagePath1C from "../img/Compressed/package.jpg"; // 确保路径正确
import imagePath3 from "../img/Compressed/PackageNew.jpeg"; // 确保路径正确
import e1 from "../img/Compressed/e1.png"; // 确保路径正确
import e2 from "../img/Compressed/e2.png"; // 确保路径正确
import e3 from "../img/Compressed/e3.png"; // 确保路径正确
import e4 from "../img/Compressed/e4.png"; // 确保路径正确
import Header from "./Header";

function PersonalCareProducts() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  const containerStyle = {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "60px", // 为头部留出空间
    boxSizing: "border-box",
    backgroundColor: "#1F1F1F",
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <img src={imagePath1C} alt="工业包装1" style={imageStyle} />
        <img src={imagePath3} alt="工业包装2" style={imageStyle} />
        <img src={e1} alt="工业包装3" style={imageStyle} />
        <img src={e2} alt="工业包装4" style={imageStyle} />
        <img src={e3} alt="工业包装5" style={imageStyle} />
        <img src={e4} alt="工业包装6" style={imageStyle} />
      </div>
    </div>
  );
}

export default PersonalCareProducts;
