import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import v1z from "../img/Compressed/v1z.jpg";
import Header from "./Header";
import tencentTigerIpCover from "../img/Cover/tencenttigeripCover.jpg";
import ecommerceVisualCover from "../img/Cover/ecommercevisualCover.jpg";
import cg3DCover from "../img/Cover/3dcgrenderingCover.jpg";
import vivoCover from "../img/Cover/VivoCover.jpg";
import oilPaintingCover from "../img/Cover/paintingCover.jpg";
import typhurCover from "../img/Cover/typhurCover.jpg";

function Visual() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate(); // 使用 useNavigate 进行页面跳转

  // 定义导航函数
  const navigateToTencentTiger = () => navigate("/tencent-tiger");
  const navigateToEcommerceVisual = () => navigate("/ecommerce-visual");
  const navigateTo3DCG = () => navigate("/3d-cg-rendering");
  const navigateToVIVO = () => navigate("/vivo");
  const navigateToOilPainting = () => navigate("/oil-painting");
  const navigateToTyphur = () => navigate("/typhur");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  return (
    <div>
      <Header />
      <div
        style={{
          position: "relative",
          top: 60,
          backgroundColor: "#1F1F1F",
          width: "100vw",
          height: "auto",
          display: "flex",
          overflow: "hidden",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={v1z} alt="李佳奇视觉作品1" style={imageStyle} />

        <div
          style={{
            maxWidth: `${maxWidth}px`,
            width: `${calculatedWidth}px`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="pt-[20px] bg-[#1F1F1F] max-w-[${maxWidth}px] w-[${calculatedWidth}px] grid grid-cols-3 sm:grid-cols-1 gap-[2vw] lg:gap-[20px] relative overflow-hidden text-[min(1.2vw,10px)] items-center justify-center"
            style={{
              borderRadius: "10px",
            }}
          >
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${tencentTigerIpCover})` }}
              onClick={navigateToTencentTiger}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                腾讯虎虎生威IP
              </motion.h1>
            </motion.div>
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${ecommerceVisualCover})` }}
              onClick={navigateToEcommerceVisual}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                欧美个护电商视觉
              </motion.h1>
            </motion.div>
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${typhurCover})` }}
              onClick={navigateToTyphur}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                Typhur 电商视觉
              </motion.h1>
            </motion.div>
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${cg3DCover})` }}
              onClick={navigateTo3DCG}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                3D渲染与CG平面
              </motion.h1>
            </motion.div>
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${vivoCover})` }}
              onClick={navigateToVIVO}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                VIVO
              </motion.h1>
            </motion.div>
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${oilPaintingCover})` }}
              onClick={navigateToOilPainting}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                手绘
              </motion.h1>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Visual;
