import React, { useState, useEffect, useRef } from "react";
import v2z from "../img/Compressed/v2z.jpg"; // 确保路径正确
import tencentVideo1 from "../img/m1.mp4";
import Header from "./Header";

function TencentTiger() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const videoRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  const videoStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
    marginBottom: "20px",
  };

  const containerStyle = {
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "60px",
    boxSizing: "border-box",
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <video
          ref={videoRef}
          style={videoStyle}
          autoPlay
          playsInline
          controls
          // 在视频元数据加载完成后设置播放速度
          onLoadedMetadata={() => {
            if (videoRef.current) {
              videoRef.current.playbackRate = 0.85;
            }
          }}
        >
          <source src={tencentVideo1} type="video/mp4" />
          您的浏览器不支持 video 标签。
        </video>
        <img src={v2z} alt="腾讯虎虎生威IP" style={imageStyle} />
      </div>
    </div>
  );
}

export default TencentTiger;
