// HuaweiPortal.js
import React from "react";
import u2z from "../img/Compressed/u2z.jpg"; // 确保路径正确
import Header from "./Header";

function HuaweiPortal() {
  // Adjust paddingTop to match the height of the Header
  const containerStyle = {
    width: "100vw",
    // height: "calc(100vh - 60px)", // Subtract the height of the header
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "60px", // Make room for the header
    boxSizing: "border-box", // Include padding in height calculation
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <div>
          <div className="text-left text-white mt-10 mb-10 shadow-text text-7xl sm:text-4xl">
            Huawei EBG Portal App
          </div>

          <img
            src={u2z}
            alt="华为门户App亿家"
            className="w-[92vw] max-w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
}

export default HuaweiPortal;
