import React, { useState, useEffect } from "react";
import Header from "./Header";
import vivoVideo1 from "../img/m4.mp4";
import vivoVideo2 from "../img/m2.m4v";
import vivomotionCover from "../img/Cover/vivomotionCover.jpg";
import vivoOriginOSCover1 from "../img/vivoOriginOSCover1.jpg";
import vivoOriginOSCover2 from "../img/vivoOriginOSCover2.jpg";
import dot22 from "../img/Reddot2022.png";

function VivoMotion() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 页面加载时滚动到顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );
  const videoStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  return (
    <div>
      <Header />
      <div
        style={{
          paddingTop: "60px",
          backgroundColor: "#1F1F1F",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          width: "100vw",
        }}
      >
        <div
          style={{
            position: "relative", // 父容器相对定位
            display: "flex", // 使用 Flexbox 布局
            justifyContent: "center", // 水平居中
            width: "100%", // 容器宽度为 100%
            maxWidth: `${calculatedWidth}px`, // 最大宽度为计算的宽度
            margin: "0 auto", // 确保容器在页面中水平居中
          }}
        >
          <img src={vivomotionCover} alt="vivomotionCover" style={imageStyle} />
          <img
            src={dot22}
            alt="Red Dot Award 2022"
            className="h-[80px] sm:h-[40px] w-auto z-[10] absolute top-[30px] right-[30px]   sm:top-[10px] sm:right-[10px]"
            style={{
              width: "auto", // 宽度自适应
              maxWidth: "100%", // 限制最大宽度为父容器宽度
            }}
          />
        </div>

        <img
          src={vivoOriginOSCover1}
          alt="vivoOriginOSCover1"
          style={imageStyle}
        />
        <img
          src={vivoOriginOSCover2}
          alt="vivoOriginOSCover2"
          style={imageStyle}
        />
        <video controls src={vivoVideo1} alt="vivo" style={videoStyle} />
        <video controls src={vivoVideo2} alt="vivo" style={videoStyle} />
      </div>
    </div>
  );
}

export default VivoMotion;
