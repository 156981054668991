import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import wz1 from "../img/wz1.jpeg"; // 主图
import Header from "./Header";
import huaweitobCover from "../img/Cover/huaweitobCover.jpg";
import usecommerceCover from "../img/Cover/usecommerceCover.jpg";
import europeecommerceCover from "../img/Cover/europeecommerceCover.jpg";
import huaweitocCover from "../img/Cover/huaweitocCover.jpg";

function Ecommerce() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate(); // 使用 useNavigate 进行页面跳转

  // 定义导航函数
  const navigateToHuaweiToB = () => navigate("/huawei-tob");
  const navigateToHuaweiToC = () => navigate("/huawei-toc");
  const navigateToUSAEcommerce = () => navigate("/us-ecommerce");
  const navigateToEuropeEcommerce = () => navigate("/europe-ecommerce");

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  return (
    <div>
      <Header />

      <div
        style={{
          position: "relative",
          top: 60,
          backgroundColor: "#1F1F1F",
          width: "100vw",
          height: "auto", // 高度自动适应图片的高度
          display: "flex",
          overflow: "hidden", // 隐藏溢出部分
          flexDirection: "column", // 垂直排列
          alignItems: "center", // 居中对齐
          justifyContent: "center", // 水平居中
        }}
      >
        <img src={wz1} alt="webImage1" style={imageStyle} />

        {/* 导航栏 */}
        <div
          style={{
            maxWidth: `${maxWidth}px`,
            width: `${calculatedWidth}px`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="pt-[20px] bg-[#1F1F1F] max-w-[${maxWidth}px] w-[${calculatedWidth}px] grid grid-cols-3 sm:grid-cols-1 gap-[2vw] lg:gap-[20px] relative overflow-hidden text-[min(1.2vw,10px)] items-center justify-center"
            style={{ borderRadius: "10px" }}
          >
            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${huaweitobCover})` }}
              onClick={navigateToHuaweiToB}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center whitespace-nowrap font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                华为ToB电商
              </motion.h1>
            </motion.div>

            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${usecommerceCover})` }}
              onClick={navigateToUSAEcommerce}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center whitespace-nowrap font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                美国个护商电商
              </motion.h1>
            </motion.div>

            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${europeecommerceCover})` }}
              onClick={navigateToEuropeEcommerce}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center whitespace-nowrap font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                欧洲个护商电商
              </motion.h1>
            </motion.div>

            <motion.div
              whileHover={{ scale: 0.9 }}
              whileTap={{ scale: 1.1 }}
              className="flex items-center justify-center rounded-[10px] overflow-hidden min-h-[200px] w-full bg-center bg-cover relative"
              style={{ backgroundImage: `url(${huaweitocCover})` }}
              onClick={navigateToHuaweiToC}
            >
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-60" />
              <motion.h1
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className="cursor-pointer text-white text-center whitespace-nowrap font-sf font-normal px-[10px] py-[20px] z-10 relative text-[20px] sm:text-[18px] lg:text-[24px]"
              >
                华为ToC电商
              </motion.h1>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Ecommerce;
