import React, { useState, useEffect } from "react";
import wzh2c from "../img/wzh2c.jpeg"; // 确保路径正确
import Header from "./Header";

function HuaweiToC() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );

  const imageStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  const containerStyle = {
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "60px",
    boxSizing: "border-box",
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <img src={wzh2c} alt="华为ToC电商" style={imageStyle} />
      </div>
    </div>
  );
}

export default HuaweiToC;
