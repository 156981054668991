import React, { useState, useEffect } from "react";
import u3z from "../img/Compressed/u3z.jpg"; // 确保路径正确
import Header from "./Header";

function TencentQQWeather() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    width: "100vw",
    display: "flex",
    flexDirection: "column", // Stack elements vertically
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "60px", // Make room for the header
    boxSizing: "border-box", // Include padding in height calculation
  };

  return (
    <div>
      <Header />
      <div style={containerStyle}>
        <div>
          <div className="text-left text-white mt-10 mb-10 shadow-text text-7xl sm:text-4xl">
            Tencent QQ Weather
          </div>
          <img
            src={u3z}
            alt="腾讯QQ天气"
            className="w-[92vw] max-w-full h-auto"
          />
        </div>
      </div>
    </div>
  );
}

export default TencentQQWeather;
