import React, { useState, useEffect } from "react";
import Header from "./Header"; // 根据实际情况选择 Header 或 NewHeader
import tencentVideo1 from "../img/m1.mp4"; // 腾讯相关视频 1
import tencentVideo2 from "../img/m5.mp4"; // 腾讯相关视频 2

function Tencent() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // 页面加载时滚动到顶部
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const maxWidth = 1048;
  const widthPercentage = 94;
  const calculatedWidth = Math.min(
    maxWidth,
    (widthPercentage / 100) * windowWidth
  );
  const videoStyle = {
    maxWidth: `${maxWidth}px`,
    width: `${calculatedWidth}px`,
    height: "auto",
  };

  return (
    <div>
      <Header />
      <div
        style={{
          paddingTop: "60px",
          backgroundColor: "#1F1F1F",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          width: "100vw",
        }}
      >
        <video controls src={tencentVideo1} alt="tencent" style={videoStyle} />
        <video controls src={tencentVideo2} alt="tencent" style={videoStyle} />
      </div>
    </div>
  );
}

export default Tencent;
